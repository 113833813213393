import ContactModal from '@/components/plus/section/ContactForm/Modal';
import {GTM_ID_PLUS} from '@/constants';
import {Theme} from '@/enums';
import type {Handle} from '@/types';

const driftBotLocales = new Set(['en', 'es', 'de', 'ja', 'it', 'fr', 'zh-CN']);

export const handle: Handle = {
  mainClass: 'pt-[73px]',
  theme: Theme.Plus,
  parentTheme: Theme.BrochureV2,
  CustomModal: ContactModal,
  gtmAccountId: GTM_ID_PLUS,
  driftBot({site}) {
    const localePrefix = site.locale.split('-')[0];
    return (
      driftBotLocales.has(site.locale) || driftBotLocales.has(localePrefix)
    );
  },
};
